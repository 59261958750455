




























































































































import { Component, Vue } from "vue-property-decorator";
import Lottery3d from "./lottery-3d.vue";
import Prize from "./lottery-prize.vue";
import LotteryMusic from "./lottery-music.vue";
import lotteryAction from "./lottery-action.vue";
import lotteryConfig from "./lottery-config.js";

@Component({
  components: {
    Lottery3d,
    Prize,
    LotteryMusic,
    lotteryAction,
  },
  created() {
    this.$store.dispatch("getLotteryUsers");
  },
})
export default class Lottery extends Vue {
  videoSrc = require("@/assets/video2.mp4");
  headerTitle = lotteryConfig.headerTitle;
}
