<template>
  <div class="lotteryAction">
    <div id="menu">
      <div style="margin-bottom: 10px;">
        <button id="tableShow" @click="tableShow">展示全部</button>
        <button id="winShow" @click="onShowWinUser">
          展示中奖
        </button>
        <button id="reset" @click="resetData">重置所有数据</button>
      </div>
    </div>
    <div
      class="showAllWinUserBox animate__animated animate__backInDown"
      v-if="showAllWinUser"
    >
      <div class="showAllWinUser">
        <div class="title">中奖者名单</div>
        <div class="prizeArea" v-for="(item, index) in winUsers" :key="index">
          <div class="prizeLevel">{{ changeLevel(index) }}</div>
          <div class="prizeDetail">
            <div class="prizeBox" v-for="subItem in item" :key="subItem[0]">
              <div class="corner-top-right"></div>
              <div class="corner-bottom-left"></div>
              <div class="prizeUser">{{ subItem[0] }}</div>
              <div class="prizeName">{{ subItem[2] }}</div>
              <div class="department">{{ subItem[1] }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="close" @click="showAllWinUser = false"></div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import { transform } from "./3d-animate.js";
import STATUS from "./3d-status.js";
import { reset, getFindWinning } from "@/api/main.js";
import "animate.css";

@Component({
  components: {},
})
export default class Prize extends Vue {
  showAllWinUser = false;
  winUsers = [];
  getRenderArr(arr) {
    const arrRes = [];
    const n = 10;
    const len = arr.length;
    const lineNum = len % n === 0 ? len / n : Math.floor(len / n + 1);
    for (let i = 0; i < lineNum; i++) {
      const temp = arr.slice(i * n, i * n + n);
      arrRes.push(JSON.parse(JSON.stringify(temp)));
    }
    return arrRes;
  }
  async tableShow() {
    if (STATUS.getStatus() !== STATUS.RUNNING_LOTTERY) {
      this.$store.commit("SET_CURRENT_PRIZE_NAME", "");
      STATUS.setStatusRun();
      await transform("table", 1000); // sphere
      STATUS.setStatusWait();
    } else {
      this.$message.warning("抽奖正在运行中，请等待后再操作！");
    }
  }
  async resetData() {
    if (confirm("是否要重置所有抽奖数据，此操作不可恢复！")) {
      try {
        const res = await reset();
        if (res.code == 200) {
          this.$message.success("重置成功");
          // 重置完了刷新一下界面
          this.$router.go(0);
        }
      } catch (err) {
        this.$message.error("重置失败");
      }
    }
  }
  async onShowWinUser() {
    try {
      const res = await getFindWinning();
      this.winUsers = [];
      if (res.code == 200) {
        Object.keys(res.msg).forEach((key) => {
          console.log(key, res.msg[key]);
          let arr = res.msg[key].map((item) => {
            return item.split("|");
          });
          this.winUsers.push(arr);
        });
        this.showAllWinUser = true;
      } else {
        this.$message.error(res.err || "获取失败");
      }
    } catch (err) {
      this.$message.error("获取失败");
    }
  }
  changeLevel(level) {
    switch (level) {
      case 0:
        return `特等奖`;
      case 1:
        return "一等奖";
      case 2:
        return "二等奖";
      case 3:
        return "三等奖";
      case 4:
        return "四等奖";
      case 5:
        return "五等奖";
      case 6:
        return "六等奖";
    }
  }
  mounted() {
    this.$bus.$on("lottery-3d-init", () => {
      STATUS.setStatusWait();
    });
  }
}
</script>

<style lang="scss" scoped>
.lotteryAction {
  flex: none;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 999;
  #menu {
    position: relative;
  }
  .showAllWinUserBox {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: url("./../../assets/prizeBg.webp") no-repeat;
    background-size: 100% 100%;
    z-index: 999;
    color: #ffd700;
    padding: 10px;
    .showAllWinUser {
      height: 100%;
      overflow-y: auto;
      .title {
        font-size: 48px;
        font-weight: 700;
        text-align: center;
        padding: 40px 0 20px;
        color: #ffffff;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        letter-spacing: 4px;
      }
      .prizeArea {
        margin: 40px auto;
        // max-width: 1200px;
        padding: 0 20px;
        .prizeLevel {
          text-align: center;
          font-size: 42px;
          font-weight: 700;
          margin-bottom: 30px;
          position: relative;
          text-shadow: 2px 2px 0 #d81e06, 4px 4px 8px rgba(0, 0, 0, 0.3);
          padding: 10px 0;

          &::before,
          &::after {
            content: "★";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: #ffd700;
            font-size: 28px;
            text-shadow: 0 0 5px rgba(255, 215, 0, 0.8),
              0 0 10px rgba(216, 30, 6, 0.5);
            font-weight: 900;
            filter: drop-shadow(0 0 2px #d81e06);
          }

          &::before {
            left: 30%;
            animation: starTwinkle 2s infinite ease-in-out;
          }

          &::after {
            right: 30%;
            animation: starTwinkle 2s infinite ease-in-out 1s;
            animation: starTwinkle 1.5s infinite ease-in-out 0.75s;
          }
        }
        .prizeDetail {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          .prizeBox {
            min-width: 200px;
            background: rgba(216, 30, 6, 0.15);
            border: 2px solid #ffd700;
            padding: 20px;
            border-radius: 15px;
            backdrop-filter: blur(8px);
            box-shadow: 0 0 15px rgba(255, 215, 0, 0.2);
            transition: all 0.3s ease;
            position: relative;

            &::before,
            &::after,
            .corner-top-right,
            .corner-bottom-left {
              content: "";
              position: absolute;
              width: 15px;
              height: 15px;
              border-style: solid;
              border-color: #ffd700;
              border-width: 0;
              pointer-events: none;
            }

            &::before {
              top: 5px;
              left: 5px;
              border-top-width: 2px;
              border-left-width: 2px;
              border-top-left-radius: 8px;
            }

            &::after {
              bottom: 5px;
              right: 5px;
              border-bottom-width: 2px;
              border-right-width: 2px;
              border-bottom-right-radius: 8px;
            }

            .corner-top-right {
              top: 5px;
              right: 5px;
              border-top-width: 2px;
              border-right-width: 2px;
              border-top-right-radius: 8px;
            }

            .corner-bottom-left {
              bottom: 5px;
              left: 5px;
              border-bottom-width: 2px;
              border-left-width: 2px;
              border-bottom-left-radius: 8px;
            }

            &:hover {
              transform: translateY(-5px) scale(1.02);
              background: rgba(216, 30, 6, 0.25);
              border-color: #fff;
              box-shadow: 0 8px 25px rgba(255, 215, 0, 0.3);

              &::before,
              &::after,
              .corner-top-right,
              .corner-bottom-left {
                border-color: #fff;
              }

              .prizeUser {
                color: #ffd700;
              }
            }

            .prizeUser {
              font-size: 24px;
              font-weight: 600;
              color: #ffffff;
              margin-bottom: 10px;
              text-align: center;
              text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
            }

            .prizeName {
              font-size: 20px;
              font-weight: 500;
              color: #ffd700;
              margin: 10px 0;
              text-align: center;
              padding: 8px 0;
              border-top: 1px solid rgba(255, 215, 0, 0.5);
              border-bottom: 1px solid rgba(255, 215, 0, 0.5);
              background: rgba(216, 30, 6, 0.1);
            }

            .department {
              font-size: 16px;
              font-weight: 400;
              color: rgba(255, 255, 255, 0.9);
              text-align: center;
              margin-top: 8px;
            }
          }
        }
      }
      // 隐藏默认滚动条
      &::-webkit-scrollbar {
        width: 0;
        display: none;
      }

      // Firefox
      scrollbar-width: none;

      // IE
      -ms-overflow-style: none;
    }
    .close {
      position: fixed;
      right: 40px;
      top: 40px;
      height: 60px;
      width: 60px;
      cursor: pointer;
      transition: all 0.3s ease;
      z-index: 1000;
      background: #d81e06;
      border-radius: 50%;
      border: 2px solid #ffd700;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      &::before {
        content: "关";
        color: #ffd700;
        font-size: 32px;
        font-weight: bold;
        transition: all 0.3s ease;
      }

      &:hover {
        transform: scale(1.1);
        background: #ff2a14;
        box-shadow: 0 4px 15px rgba(255, 215, 0, 0.3);

        &::before {
          transform: rotate(180deg);
        }
      }
    }
  }
  button {
    background: linear-gradient(145deg, #e62129 0%, #cd1c24 100%);
    color: #ffd700;
    border: 2px solid #ffd700;
    border-radius: 8px;
    padding: 12px 28px;
    font-size: 18px;
    font-weight: bold;
    margin: 0 10px;
    position: relative;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    cursor: pointer;

    &::before,
    &::after {
      content: "❧";
      color: #ffd700;
      position: absolute;
      font-size: 20px;
      top: 50%;
      transform: translateY(-50%);
    }

    &::before {
      left: 8px;
    }

    &::after {
      right: 8px;
    }

    &:hover {
      transform: translateY(-2px);
      background: linear-gradient(145deg, #ff2e37 0%, #e62129 100%);
      box-shadow: 0 6px 20px rgba(230, 33, 41, 0.4);
    }

    &:active {
      transform: translateY(1px);
      box-shadow: 0 2px 10px rgba(230, 33, 41, 0.4);
    }
  }
  .showAllWinUserBox {
    .title,
    .prizeLevel,
    .prizeBox,
    .prizeUser,
    .prizeName {
      color: inherit;
    }
    .prizeBox {
      border-color: inherit;
    }
  }
}

@keyframes starTwinkle {
  0%,
  100% {
    opacity: 1;
    transform: translateY(-50%) scale(1);
  }
  50% {
    opacity: 0.6;
    transform: translateY(-50%) scale(0.8);
  }
}
</style>
