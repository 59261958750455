















































































import { Component, Vue } from "vue-property-decorator";
import lotteryAction from "./lottery-action.vue";
import STATUS from "./3d-status.js";
import { lottery } from "@/api/main.js";
import { setSphereDist } from "./3d-calc-distance.js";
import { transform, transformStatus } from "./3d-animate.js";
import { cardFlyAnimation, rotateBall, rotateBallStop } from "./3d-action.js";

@Component({
  components: { lotteryAction },
})
export default class Prize extends Vue {
  expandedItem: string | null = null;
  // 展开/收缩奖品项
  expandItem(item) {
    if (this.expandedItem === item._id) {
      this.expandedItem = null; // 如果已经展开，则收起
    } else {
      this.expandedItem = item._id; // 展开新的项目
      this.$store.commit("SET_LOTTERY_TYPE", item.type);
    }
  }
  // 计算剩余奖品数
  countNum(info) {
    let count = 0;
    info.map((item) => {
      if (!item.status) {
        count = count + item.num;
      }
    });
    return count;
  }
  // 开始抽奖
  async lotteryStart(val, parent) {
    if (parent.type != this.$store.state.lotteryUsers.type)
      return this.$message.error("请先抽取完当前的奖项");
    if (STATUS.getStatus() !== STATUS.WAIT_LOTTERY) {
      this.$message.warning("正在抽奖或初始化，请等待一下");
      return void 0;
    }
    this.$store.commit("SET_CURRENT_PRIZE_NAME", val.name);
    let req = {
      type: parent.type,
      prizeName: val.name,
    };
    try {
      const res = await lottery(req);
      if (res.code == 200) {
        setTimeout(() => {
          this.lotteryStop(res.users);
          this.$store.dispatch("getLotteryUsers");
        }, 3000);
        // 先回到table状态再抽奖
        STATUS.setStatusRun();
        transformStatus !== "table" && (await transform("table", 500));
        await transform("sphere", 300);
        rotateBall();
      } else {
        this.$message.error(res.err);
      }
    } catch (err) {
      this.$message.error("抽取异常");
    }
  }
  // 结束旋转
  async lotteryStop(users) {
    const newUsers = users.map((item) => {
      return item.split("|");
    });
    rotateBallStop();
    const cardSelectIndex = newUsers.map((item) => {
      let index = this.$store.state.lotteryUsers.users.findIndex((val) => {
        return val.name == item[0];
      });
      return index;
    });
    await setSphereDist(2, 500);
    await cardFlyAnimation(cardSelectIndex);
    STATUS.setStatusWait();
  }
}
