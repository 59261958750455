import lotteryConfig from "./lottery-config.js";
// const { cardList, colCount, rowCount } = lotteryConfig;
import { scene, objects } from "./3d-core.js";
import store from "./../../store/index.ts";

const THREE = window.THREE;

const { CSS3DObject } = THREE;
const create3DCard = function(_objects = objects) {
  let cardList = store.state.lotteryUsers.users;
  // 中奖的卡片要染色
  const cardListWinAllIds = cardList.map((item) => {
    if (!item.status) {
      return item.name;
    }
  });

  for (let i = 0; i < cardList.length; i++) {
    const currentCardData = cardList[i];
    const element = document.createElement("div");
    element.className = "element";
    element.dataset.cardId = currentCardData.name; // 卡片唯一ID
    element.style.backgroundColor = "rgb(168, 46, 38)";
    if (cardListWinAllIds.includes(currentCardData.name)) {
      element.classList.add("prize");
    }

    const symbol = document.createElement("div");
    symbol.className = "symbol";
    symbol.textContent = currentCardData.name;
    element.appendChild(symbol);

    const details = document.createElement("div");
    details.className = "details";
    details.innerHTML = currentCardData.department;
    element.appendChild(details);

    const objectCSS = new CSS3DObject(element);
    objectCSS.position.x = Math.random() * 4000 - 2000;
    objectCSS.position.y = Math.random() * 4000 - 2000;
    objectCSS.position.z = Math.random() * 4000 - 2000;
    scene.add(objectCSS);

    objects.push(objectCSS);
  }
};

export { create3DCard };
